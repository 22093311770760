import { FC, forwardRef, useRef, useImperativeHandle, useState } from 'react';
import Swiper from 'swiper';
import Slider from 'components/commercetools-ui/atoms/slider';
import useClassNames from 'helpers/hooks/useClassNames';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { desktop, tablet } from 'helpers/utils/screensizes';
import Image from 'frontastic/lib/image';
import { ProductDetailsProps } from '../product/product-details';
import './gallery.css';

export interface GalleryImageExtra {
  url: string;
  label?: string;
}

interface GalleryProps {
  images?: Array<string>;
  imagesExtra?: Array<GalleryImageExtra>;
  inModalVersion?: ProductDetailsProps['inModalVersion'];
  firstImagePriority?: boolean;
  syncActiveIndex?: (number: number) => void;
  hideArrowsOnSingleSlide?: boolean;
  ref?: any;
}

const Gallery: FC<GalleryProps> = forwardRef(
  (
    {
      images = [],
      imagesExtra = [],
      inModalVersion,
      firstImagePriority,
      syncActiveIndex,
      hideArrowsOnSingleSlide = false,
    },
    ref,
  ) => {
    const swiperRef = useRef<Swiper>();
    const [activeSlide, setActiveSlide] = useState(0);

    const [isTabletSize] = useMediaQuery(tablet);
    const [isDesktopSize] = useMediaQuery(desktop);

    const slideTo = (slide: number) => {
      swiperRef.current?.slideTo(slide);
    };

    const handleSlide = (swiper: Swiper) => {
      setActiveSlide(swiper.realIndex);
      if (syncActiveIndex) {
        syncActiveIndex(swiper.realIndex);
      }
    };

    const imagesContainerClassName = useClassNames([
      'relative block ',
      inModalVersion ? 'max-w-[300px]' : `h-auto w-full`,
    ]);

    // Used to set from outside gallery component.
    const handleActiveSlide = (index: number) => {
      setActiveSlide(index);
      slideTo(index);
    };

    useImperativeHandle(ref, () => {
      return {
        handleActiveSlide,
      };
    });

    return (
      <div className="h-auto gap-y-34 sm:min-h-334 md:min-h-188 lg:min-h-293 xl:min-h-389">
        <Slider
          className="gallery-slider"
          onSlideChange={handleSlide}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          arrows={isTabletSize}
          dots={!isTabletSize}
          prevButtonStyles={{ left: isDesktopSize ? 6 : 0 }}
          nextButtonStyles={{ right: isDesktopSize ? 6 : 0 }}
          compactNavigation={inModalVersion}
          slidesPerView={1}
          loop
          loopedSlides={images.length || imagesExtra.length}
          hideArrowsOnSingleSlide={hideArrowsOnSingleSlide}
        >
          {imagesExtra.length > 0
            ? imagesExtra.map((image, index) => (
                <div className="px-30" key={index}>
                  <div className={imagesContainerClassName}>
                    <Image
                      className="h-auto w-full"
                      src={image.url}
                      alt={image.label}
                      title={image.label}
                      style={{ objectFit: 'contain', aspectRatio: 16 / 9, width: '100%', height: 'auto' }}
                      priority={index == 0 && firstImagePriority ? true : false}
                      height={800}
                      width={450}
                    />
                  </div>
                </div>
              ))
            : images.map((image, index) => (
                <div className={imagesContainerClassName} key={index}>
                  <Image
                    className="h-auto w-full"
                    src={image}
                    style={{ objectFit: 'contain', aspectRatio: 16 / 9, width: '100%', height: 'auto' }}
                    priority={index == 0 && firstImagePriority ? true : false}
                    height={800}
                    width={450}
                  />
                </div>
              ))}
        </Slider>

        {/* Thumbnails */}
        {!inModalVersion && (
          <div className="mt-16 hidden gap-18 md:flex md:flex-wrap">
            {imagesExtra.length > 0
              ? imagesExtra.map((image, index) => (
                  <div
                    key={index}
                    className={`relative size-112 rounded-md border p-7 ${
                      index == activeSlide % imagesExtra.length ? 'border-neutral-500' : 'border-neutral-400'
                    }`}
                  >
                    <div className="relative size-full">
                      <Image
                        src={image.url}
                        alt={image.label}
                        title={image.label}
                        className={`rounded-md p-7 hover:cursor-pointer`}
                        onClick={() => slideTo(index)}
                        style={{ objectFit: 'contain' }}
                        priority={index == 0 && firstImagePriority ? true : false}
                        height={82}
                        fill
                      />
                    </div>
                  </div>
                ))
              : images?.map((image, index) => (
                  <div
                    key={index}
                    className={`relative size-112 rounded-md border p-7 ${
                      index == activeSlide % images.length ? 'border-neutral-500' : 'border-neutral-400'
                    }`}
                  >
                    <div className="relative size-full">
                      <Image
                        src={image}
                        className={`rounded-md p-7 hover:cursor-pointer`}
                        onClick={() => slideTo(index)}
                        style={{ objectFit: 'contain' }}
                        priority={index == 0 && firstImagePriority ? true : false}
                        height={82}
                        fill
                      />
                    </div>
                  </div>
                ))}
          </div>
        )}
      </div>
    );
  },
);

export default Gallery;

Gallery.displayName = 'GalleryComponent';
