import React, { useEffect, useCallback } from 'react';
import { useParams, useSearchParams, useRouter } from 'next/navigation';
import FeedbackIconLayer from 'components/commercetools-ui/atoms/button/feedbackIconLayer';
import usePath from 'helpers/hooks/usePath';
import { getLocalStorage } from 'helpers/utils/localStorage';
import { useAccount } from 'frontastic/hooks';
import { getCookie } from 'helpers/utils/cookies';

const SSOCallback: React.FC = () => {
  const searchParams = useSearchParams();
  const idToken = searchParams.get('id_token')?.trim() || '';
  const refreshToken = searchParams.get('refresh_token')?.trim() || '';
  const url = searchParams.get('url')?.trim() || '';
  const { locale } = useParams();
  const { path } = usePath();
  const router = useRouter();
  const { getProjectConfig, getAccountForSso } = useAccount();

  const ssoRedirect = useCallback(async () => {
    const clientId = await getProjectConfig('EXTENSION_COMMERCETOOLS_COGNITO_CLIENT_ID');
    const signInUrl = await getProjectConfig('EXTENSION_COMMERCETOOLS_COGNITO_SIGN_IN_URL');
    const ssoAccountDomain = await getProjectConfig('EXTENSION_PADI_APIS_SSO_ACCOUNT_DOMAIN');
    let lvp = searchParams.get('lvp')?.trim() || '';
    const hasQuery = Object.keys(searchParams.entries()).some((e) => e !== 'url');
    if (!lvp && hasQuery) {
      const query = searchParams.toString();
      lvp = `${path}?${query}`;
      if (path && path.startsWith('/')) {
        lvp = lvp.substring(1);
      }
    }
    const loginPath = searchParams.get('loginPath')?.trim() || '';
    let ssoLink;
    if (loginPath) {
      ssoLink = `${ssoAccountDomain.setting}/login/${loginPath}?client_id=${clientId.setting}&redirect_uri=${url}/${locale}/padi-sso/callback&extraData=lvp=${lvp}`;
    } else {
      ssoLink = `${signInUrl.setting}?client_id=${clientId.setting}&redirect_uri=${url}/${locale}/padi-sso/callback&extraData=lvp=${lvp}`;
    }
    router.push(ssoLink);
  }, [url, locale]);

  const loginRedirect = useCallback(async (idToken: string) => {
    const diveShopLocalStorage = getLocalStorage('affiliateDiveShop');
    const storeNumber = diveShopLocalStorage || '';
    const impactIdCookie = getCookie('PADI.Impact.irclickid');
    const impactId = impactIdCookie || '';
    await getAccountForSso(idToken, storeNumber, impactId);
    const extraData = searchParams.get('extraData')?.trim() || '';
    let redirectUrl = '/';
    if (extraData) {
      if (extraData.includes('lvp=')) {
        // @todo Determine if lvp is needed, why not just use extraData as intended?
        const parts = extraData.split('&');
        const lvp = parts[0].replace('lvp=', '');
        redirectUrl = lvp.startsWith('http') || lvp.startsWith('/') ? lvp : `/${lvp}`;
      } else {
        redirectUrl = extraData.startsWith('http') || extraData.startsWith('/') ? extraData : `/${extraData}`;
      }
    }
    router.push(redirectUrl);
  }, []);

  useEffect(() => {
    if (!idToken) {
      ssoRedirect();
    } else {
      if (idToken) window.localStorage.setItem('idToken', idToken);
      if (refreshToken) window.localStorage.setItem('refreshToken', refreshToken);
      loginRedirect(idToken);
    }
  }, [idToken]);

  return (
    <>
      <FeedbackIconLayer loading={true} variant="secondary" />
    </>
  );
};

export default SSOCallback;
