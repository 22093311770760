import Button from 'components/commercetools-ui/atoms/button';
import Link from 'components/commercetools-ui/atoms/link';
import Typography from 'components/commercetools-ui/atoms/typography';
import Image, { ImageProps } from 'frontastic/lib/image';
import { useMemo } from 'react';
import { Reference } from 'types/reference';
import Video from '../video';

export interface HeroProps {
  image: ImageProps;
  title: string;
  ctaLabel?: string;
  ctaReference?: Reference;
  youTubeLink?: string;
  isLoopVideo: boolean;
  isAudio: boolean;
  isAutoPlay: boolean;
  body?: string;
  secondCtaLabel?: string;
  secondCtaReference?: Reference;
  height?: 'half' | 'full';
  horizontalAlign?: 'left' | 'center';
  verticalAlign?: 'top' | 'center' | 'bottom';
  imageBrightness?: boolean;
  ctaStyle?: 'primary' | 'secondary';
  secondCtaStyle?: 'primary' | 'secondary';
}

const buttonCommonClasses = 'md:px-48 md:py-12 h-40';

const Hero: React.FC<HeroProps> = ({
  image,
  title,
  ctaLabel,
  ctaReference,
  youTubeLink,
  isLoopVideo,
  isAudio,
  isAutoPlay,
  body,
  secondCtaLabel,
  secondCtaReference,
  height,
  horizontalAlign,
  verticalAlign,
  imageBrightness,
  ctaStyle,
  secondCtaStyle,
}) => {
  const cropFocus = useMemo(() => {
    if (image?.gravity?.coordinates) {
      const right = Math.ceil((image.gravity?.coordinates.x / (image.media?.width as number)) * 100);
      const top = Math.ceil((image.gravity?.coordinates.y / (image.media?.height as number)) * 100);

      return `right ${right.toString()}% top ${top.toString()}%`;
    }

    if (image?.gravity?.mode === 'center') {
      return 'center';
    }

    return 'initial';
  }, [image]);

  const wrapperClasses = useMemo(() => {
    if (height === 'half') {
      return 'relative bg-padi-gray-darkest h-[222px] md:h-[266px] lg:h-[334px]';
    }
    return 'relative bg-padi-gray-darkest h-[296px] md:h-[532px] lg:h-[668px]';
  }, [height]);

  const contentClasses = useMemo(() => {
    let verticalAlignClass = 'top-1/2  -translate-y-1/2';
    if (height !== 'half' && verticalAlign === 'top') {
      verticalAlignClass = 'top-32 md:top-64 lg:top-96';
    } else if (height !== 'half') {
      verticalAlignClass = 'bottom-32 md:bottom-64 lg:bottom-96';
    }

    return `w-full px-20 md:px-64  lg:px-[16%] text-${horizontalAlign} absolute left-0 ${verticalAlignClass}`;
  }, [horizontalAlign, verticalAlign, height]);

  const buttonClasses = useMemo(() => {
    return [ctaStyle, secondCtaStyle].map((style) => {
      if (style === 'primary') {
        return `${buttonCommonClasses} bg-padi-blue hover:bg-blue-700 text-neutral-150`;
      }
      return `${buttonCommonClasses} bg-neutral-100 hover:bg-neutral-400 border-[1px] border-padi-blue  text-padi-blue`;
    });
  }, [ctaStyle, secondCtaStyle]);

  const truncateText = (text: string | undefined, maxLength: number) => {
    if (text && text.length > maxLength) {
      return text.slice(0, maxLength) + '...';
    }
    return text;
  };
  const bodyDisplay = useMemo(() => truncateText(body, 100), [body]);
  const titleDisplay = useMemo(() => truncateText(title, 56), [title]);

  return (
    <div className="relative w-full">
      <div className={wrapperClasses}>
        {image?.media && (
          <Image
            {...image}
            priority
            loading="eager"
            alt={title}
            fill
            style={{ objectFit: 'cover', objectPosition: cropFocus }}
            className={imageBrightness ? 'brightness-75' : 'filter-none'}
          />
        )}
        {youTubeLink && (
          <Video youTubeLink={youTubeLink} isLoopVideo={isLoopVideo} isAudio={isAudio} isAutoPlay={isAutoPlay} />
        )}
      </div>

      <div className={contentClasses}>
        <Typography as="h1" className="mt-22 text-26 font-medium text-white md:mt-32 md:text-36 lg:text-48">
          {titleDisplay}
        </Typography>
        {bodyDisplay && <p className="pt-20 text-14 font-regular text-white md:text-16 lg:text-18">{bodyDisplay}</p>}
        <div className="mt-20 flex flex-wrap items-center gap-32 md:mt-24 lg:mt-32">
          {ctaLabel && (
            <Link link={ctaReference}>
              <Button className={buttonClasses[0]}>
                <Typography as="span" className="text-12  md:text-14 lg:text-16">
                  {ctaLabel}
                </Typography>
              </Button>
            </Link>
          )}
          {secondCtaLabel && (
            <Link link={secondCtaReference}>
              <Button className={buttonClasses[1]}>
                <Typography as="span" className="text-12 md:text-14 lg:text-16">
                  {secondCtaLabel}
                </Typography>
              </Button>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default Hero;
