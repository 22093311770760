'use client';

import React from 'react';
import HeaderSection, { HeaderSectionProps } from 'components/padi-ui/header-section';
import { TasticProps } from 'frontastic/tastics/types';

const HeaderSectionTastic = ({ data: { textAlign = 'left', ...data } }: TasticProps<HeaderSectionProps>) => {
  return (
    <HeaderSection
      eyebrow={data.eyebrow}
      title={data.title}
      titleHtag={data.titleHtag}
      titleSize={data.titleSize}
      body={data.body}
      textAlign={textAlign}
      bgColor={data.bgColor}
    />
  );
};

export default HeaderSectionTastic;
